// Local
// let base_url = "http://localhost:8080/api/v1";
// let base_url_2 = "http://localhost:8080/api/v2";

// Prod
const base_url = "https://api.scadmin.ca/api/v1";
const base_url_2 = "https://api.scadmin.ca/api/v2";

/* Woocommerce */
let base_url_api = "https://uskvo27wdh.execute-api.us-east-1.amazonaws.com/dev";
export let woocommerce_endpoint = `${base_url_api}/woocommerce`;

export let customer_endpoint = `${base_url}/dirtcheap/customer`;
export let item_endpoint = `${base_url}/dirtcheap/item`;
export let item_type_endpoint = `${base_url}/dirtcheap/itemType`;
export let ticket_endpoint = `${base_url}/dirtcheap/sale`;
export let ticket_endpoint_2 = `${base_url_2}/dirtcheap/sale`;
export let qrcode_endpoint = `${base_url}/dirtcheap/qrcode`;
export let lineItem_endpoint = `${base_url}/dirtcheap/lineItem`;

/* Auth */
export let login_endpoint = `${base_url}/dirtcheap/login`;
export let logout_endpoint = `${base_url}/dirtcheap/logout`;
export let create_password = `${base_url}/dirtcheap/customer/createPassword`;

/* Quickbooks */
export let quickbooks_product_endpoint = `${base_url}/quickbooks/dirtcheap/item`;
export let quickbooks_get_auth_uri_endpoint = `${base_url}/quickbooks/dirtcheap/getAuthUri`;
export let quickbooks_create_token_endpoint = `${base_url}/quickbooks/dirtcheap/createAccessToken`;
export let quickbooks_refresh_token_endpoint = `${base_url}/quickbooks/dirtcheap/refreshAccessToken`;
export let quickbooks_create_customer_endpoint = `${base_url}/quickbooks/dirtcheap/createCustomer`;
export let quickbooks_get_all_customer_endpoint = `${base_url}/quickbooks/dirtcheap/getCustomers`;
export let quickbooks_get_customer_endpoint = `${base_url}/quickbooks/dirtcheap/getCustomerInfo`;
export let quickbooks_get_customer_types_endpoint = `${base_url}/quickbooks/dirtcheap/getCustomerTypes`;
export let quickbooks_get_invoices_endpoint = `${base_url}/quickbooks/dirtcheap/getInvoices`;
export let quickbooks_make_customer_inactive_endpoint = `${base_url}/quickbooks/dirtcheap/makeCustomerInactive`;
export let quickbooks_invoice_endpoint = `${base_url}/quickbooks/dirtcheap/invoice`;
export let dirtcheap_quickbooks_make_customer_inactive_endpoint = `${base_url}/quickbooks/dirtcheap/makeCustomerInactive`;
export let quickbooks_check_customer_exist_endpoint = `${base_url}/quickbooks/dirtcheap/checkCustomerExist`;
export let quickbooks_create_invoice_woocommerce_endpoint = `${base_url}/quickbooks/dirtcheap/createInvoiceWoocommerce`;
